var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isProfileLoading())?_c('Splash'):(_vm.$route.path === '/profile/me/edit' && _vm.$mq === 'sm')?_c('div',[_c('router-view')],1):_c('WithSidebar',{scopedSlots:_vm._u([{key:"main",fn:function(ref){
var toggleSidebarLeft = ref.toggleSidebarLeft;
return [_c('div',{staticClass:"profile bg-light text-primary"},[_c('div',{staticClass:"profile__scrollable-content"},[_c('ProfileHeader',{attrs:{"profile":_vm.getMyCurrentProfile()}}),(_vm.$mq === 'sm')?_c('header',{staticClass:"header header--absolute"},[_c('div',{staticClass:"left"},[_c('CurrentProfileBurger',{on:{"click":toggleSidebarLeft}})],1),_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo_white.svg"),"alt":"logo"}}),_c('img',{attrs:{"src":require("@/assets/ellipsis_white.svg"),"alt":"ellipsis"}})]):_vm._e(),_c('div',{staticClass:"profile__feed"},[_c('h3',[_vm._v("Status updates")]),_c('Post',{attrs:{"data":{
                        avatar: require("@/assets/Goose_2.png"),
                        name: 'Gus the Goose',
                        date: new Date(),
                        text: 'Når alt er på plass, vil denne feeden inneholde relevant informasjon for deg basert på dine interesser og hvor du bor. Enn så lenge vil jeg; Gus the Goose komme med noen små oppdateringer fremover, Rock on!',
                        image: require("@/assets/Goose_2.png")
                    }}})],1)],1),(false)?_c('div',{staticClass:"status-control bg-white"},[_c('div',{staticClass:"status-control__input"},[_c('img',{staticClass:"avatar-sm",attrs:{"src":_vm.getMyCurrentProfile().avatar,"alt":"avatar"}}),_c('BaseInput',{attrs:{"size":"small","type":"text","text-align":"left","placeholder":"Share something goose bumping..."}})],1),_c('ButtonGroup',{attrs:{"fluid":""}},[_c('Button',{attrs:{"label":"Music","fluid":"","size":"small"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('img',{staticClass:"icon-xsm",attrs:{"src":require("@/assets/icons/music_icon.svg")}})]},proxy:true}],null,true)}),_c('Button',{attrs:{"label":"Picture","fluid":"","size":"small"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('img',{staticClass:"icon-sm",attrs:{"src":require("@/assets/icons/image_icon.svg")}})]},proxy:true}],null,true)}),_c('Button',{attrs:{"label":"Video","fluid":"","size":"small"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('img',{staticClass:"icon-sm",attrs:{"src":require("@/assets/icons/video_camera_icon.svg")}})]},proxy:true}],null,true)})],1)],1):_vm._e(),(_vm.$route.path === '/profile/me/edit')?_c('Modal',{attrs:{"scrollable":"","round":""}},[_c('div',{style:({ padding: '4rem', width: '768px' })},[_c('router-view')],1)]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }