<template>
    <Splash v-if="isProfileLoading()" />
    <div v-else-if="$route.path === '/profile/me/edit' && $mq === 'sm'">
        <router-view />
    </div>
    <WithSidebar v-else>
        <template #main="{ toggleSidebarLeft }">
            <div class="profile bg-light text-primary">
                <div class="profile__scrollable-content">
                    <ProfileHeader :profile="getMyCurrentProfile()"> </ProfileHeader>
                    <header v-if="$mq === 'sm'" class="header header--absolute">
                        <div class="left">
                            <CurrentProfileBurger @click="toggleSidebarLeft" />
                        </div>
                        <img class="logo" src="@/assets/logo_white.svg" alt="logo" />
                        <img src="@/assets/ellipsis_white.svg" alt="ellipsis" />
                    </header>
                    <div class="profile__feed">
                        <h3>Status updates</h3>
                        <Post :data="{
                            avatar: require(`@/assets/Goose_2.png`),
                            name: 'Gus the Goose',
                            date: new Date(),
                            text: 'Når alt er på plass, vil denne feeden inneholde relevant informasjon for deg basert på dine interesser og hvor du bor. Enn så lenge vil jeg; Gus the Goose komme med noen små oppdateringer fremover, Rock on!',
                            image: require(`@/assets/Goose_2.png`)
                        }" />
                        <!-- <Post
                            v-for="i in 3"
                            :key="i"
                            :data="{
                                avatar: getMyCurrentProfile().avatar,
                                name: getMyCurrentProfile().displayName,
                                date: new Date(),
                                text: $faker().lorem.paragraph(),
                                image: require('@/assets/austin.png')
                            }"
                        /> -->
                    </div>
                </div>
                <div v-if="false" class="status-control bg-white">
                    <div class="status-control__input">
                        <img class="avatar-sm" :src="getMyCurrentProfile().avatar" alt="avatar" />
                        <BaseInput
                            size="small"
                            type="text"
                            text-align="left"
                            placeholder="Share something goose bumping..."
                        />
                    </div>
                    <ButtonGroup fluid>
                        <Button label="Music" fluid size="small">
                            <template #prepend>
                                <img class="icon-xsm" src="@/assets/icons/music_icon.svg" />
                            </template>
                        </Button>
                        <Button label="Picture" fluid size="small">
                            <template #prepend>
                                <img class="icon-sm" src="@/assets/icons/image_icon.svg" />
                            </template>
                        </Button>
                        <Button label="Video" fluid size="small">
                            <template #prepend>
                                <img class="icon-sm" src="@/assets/icons/video_camera_icon.svg" />
                            </template>
                        </Button>
                    </ButtonGroup>
                </div>
                <Modal v-if="$route.path === '/profile/me/edit'" scrollable round>
                    <div :style="{ padding: '4rem', width: '768px' }">
                        <router-view></router-view>
                    </div>
                </Modal>
            </div>
        </template>
    </WithSidebar>
</template>

<script>
import Button from '@/components/Button.vue'
import Post from '@/components/Post.vue'
import WithSidebar from '@/components/layout/WithSidebar'
import BaseInput from '@/components/BaseInput'
import ButtonGroup from '@/components/ButtonGroup'
import CurrentProfileBurger from '@/components/CurrentProfileBurger'
import ProfileHeader from './ProfileHeader'
import Modal from '@/components/modals/Modal'
export default {
    name: 'MyProfile',
    components: {
        Button,
        ButtonGroup,
        BaseInput,
        Post,
        WithSidebar,
        CurrentProfileBurger,
        ProfileHeader,
        Modal
    },
    inject: ['getMyCurrentProfile', 'isProfileLoading'],

    data() {
        return {
            posts: [
                {
                    name: 'Austin The Singer',
                    avatar: 'concert.png',
                    date: new Date(),
                    content: 'Had a great time yesterday performing at @GooseRockFestival',
                    contentImage: 'concert.png'
                },
                {
                    name: 'Kanye East',
                    avatar: 'bird_splash.png',
                    date: new Date(),
                    content: 'I am Kanye East',
                    contentImage: 'bird_splash.png'
                },
                {
                    name: 'Michael',
                    avatar: 'bird_splash_shadow.png',
                    date: new Date(),
                    content: 'Have a nice day everyone',
                    contentImage: 'concert.png'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';
.wrapper {
    width: 100vw;
    height: 100vh;
}
.separator {
    height: 1px;
    background: $light;
    width: 100%;
}

.header {
    height: 90px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 1rem 1rem 0.5rem;
    align-content: end;
    align-items: center;
    background: transparent;
    border-bottom: 0;
    position: absolute;
    top: 0;
    .image-burger-container {
        background: radial-gradient(rgba(0, 0, 0, 1) -200%, transparent);
        box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.1);
    }
    .logo {
        justify-self: center;
        width: 50px;
        background: radial-gradient(rgba(0, 0, 0, 1) -200%, transparent);
        box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.1);
    }
    > :last-child {
        justify-self: end;
        width: 20px;
    }
}

.profile {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    &__scrollable-content {
        overflow: auto;
        height: 100%;
        position: relative;
    }

    &__feed {
        padding: 2rem 1rem 110px;
        h3 {
            padding-left: 1rem;
            padding-bottom: 1rem;
        }
    }
}

.status-control {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem 1rem calc(90px + 1rem);
    display: grid;
    row-gap: 1rem;
    &__input {
        display: grid;
        grid-template-columns: auto 1fr;
        place-items: center;
        column-gap: 0.5rem;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .profile {
        // min-height: 100vh;
        height: auto;
        overflow: visible;
        position: static;

        &__scrollable-content {
            overflow: inherit;
            height: 100%;
            position: static;
        }

        &__feed {
            max-width: 700px;
            margin: auto;
            padding: 1rem;
        }
    }
}
</style>
