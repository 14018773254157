<template>
    <div class="button-group" :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ButtonGroup',
    props: {
        fluid: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'button-group--fluid': this.fluid
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.button-group {
    button {
        border-radius: 0;

        &:not(:last-child) {
            border-right: none;
        }

        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
}

.button-group--fluid {
    width: 100%;
}
</style>
